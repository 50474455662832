var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"fd7dec5e98f9dba1d6365e90480dacc0d73b43e7"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://542d2ccab0f64d5cbad472a6b43b10ee@o1327359.ingest.sentry.io/4505121457176576',
    tracesSampleRate: 1.0,
  });
}
